import React, { Suspense, lazy } from "react"
import "./Clutch.scss"

// Lazy load the components
const CFReviews = lazy(() => import("./CFReviews"))
const CustomSlider = lazy(() => import("./CustomSlider"))
const WhyPartner = lazy(() => import("./WhyPartner"))

const Clutch = () => {
  return (
    <div className="main-component">
      <div className="content-container">
        {/* Wrap each component with Suspense and provide a fallback */}
        <Suspense fallback={<div>Loading WhyPartner...</div>}>
          <WhyPartner />
        </Suspense>

        <Suspense fallback={<div>Loading CFReviews...</div>}>
          <CFReviews />
        </Suspense>

        <Suspense fallback={<div>Loading CustomSlider...</div>}>
          <CustomSlider />
        </Suspense>
      </div>
    </div>
  )
}

export default Clutch
