import React, { useState } from "react"
import Button from "../../shared/Button"
import "./Footer.scss"
import Select from "./SelectHook"
import { navigate } from "gatsby"
import Loader from "../../utils/Loader"
import { sendMail } from "../../utils/helpers"

const helpOptions = [
  {
    id: 1,
    name: "Product Strategy",
    value: "Product Strategy",
    budget: false,
  },
  {
    id: 2,
    name: "Product Design",
    value: "Product Design",
    budget: false,
  },
  {
    id: 3,
    name: "Custom Software Development",
    value: "Custom Software Development",
    budget: false,
  },
  {
    id: 4,
    name: "Data Engineering",
    value: "Data Engineering",
    budget: false,
  },
  {
    id: 5,
    name: "Team Augmentation",
    value: "Team Augmentation",
    budget: false,
  },
  {
    id: 6,
    name: "Partnership",
    value: "Partnership",
    budget: true,
  },
  {
    id: 7,
    name: "Other",
    value: "Other",
    budget: true,
  },
]
const budgetOptions = [
  {
    id: 1,
    name: "$5000-$10000",
    value: "5000-10000",
  },
  {
    id: 2,
    name: "$10000-$50000",
    value: "10000-50000",
  },
  {
    id: 3,
    name: "$50000+",
    value: "50000",
  },
]

export default function ContactForm() {
  const [open, setOpen] = React.useState(false)
  const [isFailed, setIsFailed] = React.useState(false)
  const [clearSelectFields, setClearSelectFields] = React.useState(false) //1

  const [state, setState] = useState({
    name: "",
    email: "",
    company: "",
    help: "",
    budget: "",
    message: "",
  })

  const [budgetDisable, setbudgetDisable] = useState(true)

  const onChangeHandler = e => {
    const value = e.target.value
    if (e.target.name === "help") {
      const val = helpOptions.filter(item => item.name === e.target.value)
      setbudgetDisable(val[0].budget)
    }
    setState({
      ...state,
      [e.target.name]: value,
    })
    setIsFailed(false)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (state.name.length > 25) return
    setOpen(true)
    const body = {
      to: "sales@codefulcrum.com", // Change to your recipient
      subject: `Client Query: ${state.help}`,
      name: `Client Query: ${state.name}`,
      html: `
      <table style=" border: 1px solid; width: 100%; border-collapse: collapse; color:black; text-align:center">
      <thead>
        <tr>
        <th style=" border: 1px solid">Name</th>
        <th style=" border: 1px solid">Email</th>
        <th style=" border: 1px solid">Company</th>
        <th style=" border: 1px solid">Service</th>
        <th style=" border: 1px solid">Budget</th>
        <th style=" border: 1px solid">Message</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <th style=" border: 1px solid; font-weight:normal">${state.name}</th>
        <td style=" border: 1px solid">${state.email}</td>
        <td style=" border: 1px solid">${state.company}</td>
        <td style=" border: 1px solid">${state.help}</td>
        <td style=" border: 1px solid">${state.budget}</td>
        <td style=" border: 1px solid">${state.message}</td>
        </tr>
      </tbody>
      </table> 
      `,
    }

    sendMail(body)
      .then(res => {
        if (res.status == 200) {
          navigate("/thank-you")
        } else {
          setIsFailed(true)
        }
        setOpen(false)
      })
      .catch(err => {
        setIsFailed(true)
        setOpen(false)
      })
  }

  const errorText = <span style={{ color: "red" }}>Enter a valid name</span>

  const clearButton = () => {
    const form = document.forms["contact"]
    form.reset()
    setClearSelectFields(true)
    setIsFailed(false)
    setOpen(false)
  }

  return (
    <>
      <Loader open={open} />

      <form name="contact" onSubmit={handleSubmit}>
        <div className="row" style={{ rowGap: "2rem" }}>
          <div className="col-md-6 col-sm-12 mb-4">
            <label className="custom-input-label">
              Name <span className="required-asterisk">*</span>
            </label>
            <input
              type="text"
              className="custom-input"
              name="name"
              required
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-md-6 col-sm-12 mb-4">
            <label className="custom-input-label">
              Email <span className="required-asterisk">*</span>
            </label>
            <input
              type="email"
              className="custom-input"
              name="email"
              required
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-md-6 col-sm-12 mb-4">
            <label className="custom-input-label">Company</label>
            <input
              type="text"
              className="custom-input"
              name="company"
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-md-6 col-sm-12 form-fields mb-4">
            <Select
              options={helpOptions}
              label="How can we help you?"
              name="help"
              value={state.help}
              onChange={onChangeHandler}
              clearSelectFields={clearSelectFields}
              resetSelectClearState={() => setClearSelectFields(false)}
              required
            />
          </div>
          <div className="col-md-6 col-sm-12 form-fields mb-4">
            <Select
              options={budgetOptions}
              label="Budget"
              name="budget"
              value={state.budget}
              disabled={budgetDisable}
              onChange={onChangeHandler}
              clearSelectFields={clearSelectFields}
              resetSelectClearState={() => setClearSelectFields(false)}
            />
          </div>
          <div className="col-12 form-fields mb-4">
            <textarea
              onChange={onChangeHandler}
              name="message"
              cols="30"
              rows="4"
              placeholder="Message"
            ></textarea>
          </div>
        </div>
        {isFailed && (
          <div class="alert alert-danger text-start " role="alert">
            Message sending failed
          </div>
        )}
        <div className="text-right pe-0 pt-3">
          <Button
            text="CANCEL"
            variant="fill"
            border="#2f2f2f"
            bgClr="#ffffff"
            clr="#2f2f2f"
            className="me-3"
            onClick={clearButton}
            type="button"
          />
          <Button
            text="SEND MESSAGE"
            variant="fill"
            bgClr="#f23b48"
            type="submit"
          />
        </div>
      </form>
    </>
  )
}
