import React, { Suspense, lazy } from "react"
import Button from "../../shared/Button"
import "./blogSection.scss"
// Lazy load the BlogCard component
const Card = lazy(() => import("../../shared/BlogCard"))
import blogs from "../../shared/Data/blogs"

function BlogSection() {
  const topBlogs = blogs
    .slice(0)
    .reverse()
    .filter((blog, index) => index < 3)

  return (
    <div className="container">
      <div className="blog-section-wrapper">
        <Suspense fallback={<div>Loading...</div>}>
          <Card cardData={topBlogs.slice(0).reverse()} col={4} gap={4} />
        </Suspense>
        <div className="btn-wrapper">
          <Button text="View All Blogs" variant="fill" href="/blogs" />
        </div>
      </div>
    </div>
  )
}

export default BlogSection
