import React from "react"
import loadable from "@loadable/component"
import Hero from "../shared/Hero"
import ContactUs from "../shared/Footer/ContactUs"
import BlogSection from "./BlogSection"
import Clutch from "./Clutch"

// Use loadable for dynamic imports
const AboutUsSection = loadable(() => import("./AboutUsSection"))
const ServicesSection = loadable(() => import("./ServicesSection"))
const WorkSwiper = loadable(() => import("./WorkSwiper"))
const Achievments = loadable(() => import("./Achievments/Achievments"))
const ClientSection = loadable(() => import("./ClientSection"))

export default function Homepage() {
  return (
    <>
      <Hero
        id="top"
        social={true}
        btnText="BOOK A MEETING"
        text="Empowering businesses with cost-effective software solutions, efficient resource allocation, transparent communication, and streamlined work processes."
        scroll="#about_us_section"
      >
        <h1 style={{ fontDisplay: "swap" }}>
          Where Elite Coding Expertise Meets{" "}
          <span style={{ color: "#f23b48", fontDisplay: "swap" }}>
            Business Brilliance{" "}
          </span>
        </h1>
      </Hero>

      {/* Load components dynamically using @loadable/component */}
      <ClientSection fallback={<div>Loading Client Section...</div>} />
      <AboutUsSection fallback={<div>Loading About Us Section...</div>} />
      <ServicesSection fallback={<div>Loading Services Section...</div>} />
      <WorkSwiper fallback={<div>Loading Work Section...</div>} />
      <Achievments fallback={<div>Loading Achievements...</div>} />
      <BlogSection fallback={<div>Loading Blog Section...</div>} />
      <Clutch fallback={<div>Loading Clutch Section...</div>} />
      <ContactUs fallback={<div>Loading Contact Section...</div>} />
    </>
  )
}
